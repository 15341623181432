/* App.css */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  color: #333;
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
  margin-top: 50px;
}

form {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input,
select {
  width: 90%;
  padding: 10px;
  margin: 5px 0 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

button {
  background-color: #e74c3c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #c0392b;
}

.error {
  color: red;
  font-weight: bold;
}
